<template>
    <b-modal v-model="openedModal" centered hide-footer>
        <template #modal-title>
            <h5 v-if="type === 'add'">Dodaj</h5>
            <h5 v-else>Uredi</h5>
        </template>
        <validation-observer ref="validate">
            <b-form>
                <b-form-group label="Ime">
                    <validation-provider #default="{ errors }" name="ime" rules="required|min:3|max:30">
                        <b-form-input v-model="user.name" placeholder="Ime"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Priimek">
                    <validation-provider #default="{ errors }" name="priimek" rules="required|min:3|max:40">
                        <b-form-input v-model="user.surname" placeholder="Priimek"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Email">
                    <validation-provider #default="{ errors }" name="email" rules="required|email">
                        <b-form-input v-model="user.email" placeholder="Email"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-if="!admin" label="Telefon" class="position-relative">
                    <span style="position: absolute; right: 0; top: -25px;">
                        <fa class="ml-1" id="supported-phone-formats-tooltip" icon="question-circle" />
                        <b-tooltip target="supported-phone-formats-tooltip" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                            Podprti formati:<br>
                            +386 51 123 456<br>
                            00386 51 123 456<br>
                            051 123 456
                        </b-tooltip>
                    </span>
                    <validation-provider  #default="{ errors }" name="Telefon" rules="required|phone_number">
                        <b-form-input v-model="user.telephone_number" placeholder="Telefon"/>
                        <small class="text-danger">
                            {{ errors[0] }}
                        </small>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-if="!admin" label="Šifra plačnika" class="position-relative">
                    <span style="position: absolute; right: 0; top: -25px;">
                        <fa class="ml-1" id="code-payer-tooltip" icon="question-circle" />
                        <b-tooltip target="code-payer-tooltip" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                            Šifro najdete na računu v zgornjem desnem kotu in je v formatu xxx-xxx-xxx
                        </b-tooltip>
                    </span>
                    <validation-provider #default="{ errors }" name="šifra plačnika" rules="required">
                        <b-form-input class="register" v-model="user.code_payer" placeholder="Šifra plačnika" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group v-if="!admin" label="Upravnik">
                    <validation-provider #default="{ errors }" name="upravnik" rules="required">
                        <Select v-model="user.manager" :options="managers" :clearable="false" text="Upravnik"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Geslo" v-if="type === 'add'">
                    <validation-provider #default="{ errors }" name="geslo" rules="required">
                        <b-input-group class="input-group-merge">
                            <b-form-input id="login-password" v-model="user.password" class="form-control-merge" :type="passwordFieldType" placeholder="Geslo"/>
                            <b-input-group-append is-text>
                                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"  @click="togglePasswordVisibility"/>
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Geslo" v-else>
                    <b-input-group class="input-group-merge">
                        <b-form-input id="login-password" v-model="user.password" class="form-control-merge" :type="passwordFieldType" placeholder="Geslo"/>
                        <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"  @click="togglePasswordVisibility"/>
                        </b-input-group-append>
                    </b-input-group>
                    <b-alert class="mt-1" show variant="warning">Če je polje za geslo prazno, bo geslo ostalo nespremenjeno.</b-alert>
                </b-form-group>
                <b-form-group v-if="type === 'add'" class="text-right">
                    <b-button variant="secondary" @click="validationFormAdd">DODAJ</b-button>
                </b-form-group>
                <b-form-group v-else-if="type === 'edit'" class="text-right">
                    <b-button variant="secondary" @click="validationFormEdit">UREDI</b-button>
                </b-form-group>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, PhoneNumber } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import Select from '@/views/Components/Select.vue'
    import {BAlert, BFormGroup, BFormInput, BInputGroup, BModal, BInputGroupAppend, BButton, BTooltip, BForm} from 'bootstrap-vue'
    export default {
        props: {
            type: {
                type: String,
                reqired: true
            },
            user: {
                type: Object,
                reqired: true
            },
            admin: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Select,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BAlert,
            BModal,
            BInputGroupAppend,
            BButton,
            BTooltip,
            BForm
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                openedModal: false,
                text: 'Dodaj',
                managers: this.$store.getters['user/managers'],
                required,
                email,
                PhoneNumber
            }
        },
        methods: {
            validationFormAdd() {
                
                this.$refs.validate.validate().then(success => {
                    if (success) {
                      
                        this.addUser()
                    }
                })
            },
            validationFormEdit() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        this.editUser()
                    }
                })
            },
            openModal() {
                if (this.type === 'edit') {
                    this.text = 'Uredi'
                }
                this.openedModal = true
            },
            addUser() {
                const thisIns = this
                const loadPromise = this.$http.put('/api/management/v1/user/', this.user)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Uporabnik je dodan')
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            editUser() {
                const thisIns = this
                const loadPromise = this.$http.patch(`/api/management/v1/user/${this.user.id}`, this.user)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Uporabnik je urejen')
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <b-overlay :show="showLoader">
        <b-card>
            <b-button variant="primary" @click="addUser" v-if="$hasPermissions($permissions.EditStaninvestUsers) || $hasPermissions($permissions.EditHabitUsers)">Dodaj uporabnika</b-button>
            <Table ref="table" class="mt-1" :fields="fields" route="/api/management/v1/user" :fromElastic="false">
                <template #cell(email_verified)="row">
                    <b-badge v-if="row.item.email_verified" variant="light-success">
                        Da
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        Ne
                    </b-badge>
                </template>
                <template #cell(actions)="row">
                    <div class="d-flex justify-content-center" >
                        <b-button v-if="(row.item.manager === 'staninvest' && $hasPermissions($permissions.LogInAsUserStaninvest)) || (row.item.manager === 'habit' && $hasPermissions($permissions.LogInAsUserHabit))" class="mr-1" @click="loginAsUser(row.item)" ><fa icon="eye"/></b-button>
                        <b-button v-if="(row.item.manager === 'staninvest' && $hasPermissions($permissions.EditStaninvestUsers)) || (row.item.manager === 'habit' && $hasPermissions($permissions.EditHabitUsers))" class="mr-1" variant="warning" @click="editUser(row.item)"><fa icon="edit"/></b-button>
                        <b-button v-if="(row.item.manager === 'staninvest' && $hasPermissions($permissions.EditStaninvestUsers)) || (row.item.manager === 'habit' && $hasPermissions($permissions.EditHabitUsers))" @click="deleteUser(row.item.id)" variant="danger"><fa icon="trash"/></b-button>
                    </div>
                </template>
            </Table>
        </b-card>
        <UserModal ref="userModal" :type="type" :user="user" v-on:success="getItems()"/>
    </b-overlay>
</template>
<script>
    import UserModal from '@/views/Admin/Users/UserModal'
    import Table from '@/views/Components/Table'
    import {BBadge, BButton, BCard, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            BBadge,
            UserModal,
            Table,
            BOverlay,
            BCard,
            BButton
        },
        data() {
            return {
                showLoader: false,
                user: {name: '', surname: '',  email: '', telephone_number: '', password: '', code_payer: '', administrator: false, manager: ''},
                type: '',
                fields: [
                    { key: 'name', label: 'Ime', sortable: true, class: 'text-center' },
                    { key: 'surname', label: 'Priimek', sortable: true, class: 'text-center' },
                    { key: 'email', label: 'Email', sortable: true, class: 'text-center' },
                    { key: 'telephone_number', label: 'Telefonska številka', sortable: true, class: 'text-center' },
                    { key: 'manager', label: 'Upravnik', sortable: true, class: 'text-center' },
                    { key: 'code_payer', label: 'Šifra plačnika', sortable: true, class: 'text-center' },
                    { key: 'bbis_customer_id', label: 'BBIS ID', sortable: true, class: 'text-center' },
                    { key: 'join_date_formatted', label: 'Datum registracije', sortable: true, class: 'text-center' },
                    { key: 'unit_addresses', label: 'Nalovi enot', sortable: true, class: 'text-center', sortByFormatted: true, filterByFormatted: true, formatter: (value) => { return value ? value.join(', ') : '/' } },
                    { key: 'email_verified', label: 'Email preverjen', sortable: true, class: 'text-center', type: 'bool' }
                ]
            }
        },
        methods: {
            getItems() {
                setTimeout(function() {
                    this.$refs.table.search()
                }.bind(this), 1000)
            },
            addUser() {
                this.type = 'add'
                this.user = {name: '', surname: '',  email: '', telephone_number: '', password: '', code_payer: '', administrator: false, manager: '' }
                this.$refs.userModal.openModal()
            },
            async loginAsUser(user) {
                this.showLoader = true
                try {
                    const response = await this.$http.get(`/api/management/v1/user/${user.id}/login_as_user`)
                    window.open(`https://esoseska.si/login_as_user/${response.data.token}`, '_blank').focus()
                    //window.open(`http://localhost:8081/login_as_user/${response.data.token}`, '_blank').focus()
                } catch (error) {
                    this.$printError(error.message)
                } finally {
                    this.showLoader = false
                }
            },
            editUser(user) {
                this.type = 'edit'
                this.user = user
                this.$refs.userModal.openModal()
            },
            deleteUser(id) {
                this.showLoader = true
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati uporabnika.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                }).then(value => {
                    if (value) {
                        const thisIns = this
                        const loadPromise = this.$http.delete(`/api/management/v1/user/${id}`)
                        loadPromise.then(function() {
                            thisIns.$printSuccess('Uporabnik je izbrisan')
                        }).catch(function(error) {
                            thisIns.$printError(error.response.data)
                        }).finally(function() {
                            thisIns.showLoader = false
                            thisIns.$refs.table.search()
                        })
                    }
                })
            }
        },
        mounted() {
            if (this.$hasPermissions(this.$permissions.EditHabitUsers) || this.$hasPermissions(this.$permissions.EditStaninvestUsers) || this.$hasPermissions(this.$permissions.LogInAsUserHabit) || this.$hasPermissions(this.$permissions.LogInAsUserStaninvest)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    }
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [(_vm.type === 'add')?_c('h5',[_vm._v("Dodaj")]):_c('h5',[_vm._v("Uredi")])]},proxy:true}]),model:{value:(_vm.openedModal),callback:function ($$v) {_vm.openedModal=$$v},expression:"openedModal"}},[_c('validation-observer',{ref:"validate"},[_c('b-form',[_c('b-form-group',{attrs:{"label":"Ime"}},[_c('validation-provider',{attrs:{"name":"ime","rules":"required|min:3|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Ime"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Priimek"}},[_c('validation-provider',{attrs:{"name":"priimek","rules":"required|min:3|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Priimek"},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.admin)?_c('b-form-group',{staticClass:"position-relative",attrs:{"label":"Telefon"}},[_c('span',{staticStyle:{"position":"absolute","right":"0","top":"-25px"}},[_c('fa',{staticClass:"ml-1",attrs:{"id":"supported-phone-formats-tooltip","icon":"question-circle"}}),_c('b-tooltip',{attrs:{"target":"supported-phone-formats-tooltip","placement":"topright","triggers":"hover focus","custom-class":"expanded-tooltip"}},[_vm._v(" Podprti formati:"),_c('br'),_vm._v(" +386 51 123 456"),_c('br'),_vm._v(" 00386 51 123 456"),_c('br'),_vm._v(" 051 123 456 ")])],1),_c('validation-provider',{attrs:{"name":"Telefon","rules":"required|phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Telefon"},model:{value:(_vm.user.telephone_number),callback:function ($$v) {_vm.$set(_vm.user, "telephone_number", $$v)},expression:"user.telephone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,831266078)})],1):_vm._e(),(!_vm.admin)?_c('b-form-group',{staticClass:"position-relative",attrs:{"label":"Šifra plačnika"}},[_c('span',{staticStyle:{"position":"absolute","right":"0","top":"-25px"}},[_c('fa',{staticClass:"ml-1",attrs:{"id":"code-payer-tooltip","icon":"question-circle"}}),_c('b-tooltip',{attrs:{"target":"code-payer-tooltip","placement":"topright","triggers":"hover focus","custom-class":"expanded-tooltip"}},[_vm._v(" Šifro najdete na računu v zgornjem desnem kotu in je v formatu xxx-xxx-xxx ")])],1),_c('validation-provider',{attrs:{"name":"šifra plačnika","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"register",attrs:{"placeholder":"Šifra plačnika"},model:{value:(_vm.user.code_payer),callback:function ($$v) {_vm.$set(_vm.user, "code_payer", $$v)},expression:"user.code_payer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2617626780)})],1):_vm._e(),(!_vm.admin)?_c('b-form-group',{attrs:{"label":"Upravnik"}},[_c('validation-provider',{attrs:{"name":"upravnik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.managers,"clearable":false,"text":"Upravnik"},model:{value:(_vm.user.manager),callback:function ($$v) {_vm.$set(_vm.user, "manager", $$v)},expression:"user.manager"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2253537260)})],1):_vm._e(),(_vm.type === 'add')?_c('b-form-group',{attrs:{"label":"Geslo"}},[_c('validation-provider',{attrs:{"name":"geslo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","type":_vm.passwordFieldType,"placeholder":"Geslo"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2401695011)})],1):_c('b-form-group',{attrs:{"label":"Geslo"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","type":_vm.passwordFieldType,"placeholder":"Geslo"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('b-alert',{staticClass:"mt-1",attrs:{"show":"","variant":"warning"}},[_vm._v("Če je polje za geslo prazno, bo geslo ostalo nespremenjeno.")])],1),(_vm.type === 'add')?_c('b-form-group',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.validationFormAdd}},[_vm._v("DODAJ")])],1):(_vm.type === 'edit')?_c('b-form-group',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.validationFormEdit}},[_vm._v("UREDI")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }